import React, { Component } from "react";
import { Container } from "native-base";
import TryalButton from "../common/button";
import { View, Text, Platform, TouchableOpacity } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { ButtonTypes } from "../common/constants";
import { Colors } from "../../constants/ui/colorScheme";
import { fontType } from "../../styles/font";
import { signatureButtonStyles, constStyles } from "../../styles/constStyles";
import { CommonActions } from "@react-navigation/native";
import PropTypes from "prop-types";
import _ from "lodash";
import { generateToken, retrieveSubjectDetails } from "../../actions/subject";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import showToast from '../../utils/toast'

class CompletedScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getSignatureButton = (type) => {
    const { isRemoteConsent } = this.props;
    if (!isRemoteConsent) {
      switch (type) {
        case 1:
          return "Go to Subject Signatures";
        case 2:
          return "Go to LAR Signatures";
        case 4:
          return "Go to Witness Signatures";
        default:
          break;
      }
    }
  };
  getNextSignatureType = () => {
    const { currentUserType, signatureTypes } = this.props;
    if (!_.isEmpty(signatureTypes)) {
      const type = signatureTypes[0];
      return type;
    }
    return null;
  };
  navigateToNextSignatures = async(signType) => {
    const {
      generateToken,
      selectedSubject,
      setCurrentUserType,
      navigation,
      clearDocSignPosition,
      clearSubjectDocumentSignatures,
      isRemoteConsent,
      retrieveSubjectDetails,
      screenProps: { t }
    } = this.props;
    try{
      if(!isRemoteConsent){
        await generateToken(selectedSubject, signType);
        await retrieveSubjectDetails(selectedSubject.id)
      }
      clearDocSignPosition();
      setCurrentUserType(signType);
      clearSubjectDocumentSignatures();
      if (signType == 4) {
        navigation.navigate("Consent", { screen: "SubDocsPage" });
      } else {
        navigation.navigate("Consent", { screen: "ConsentOverviewScreen" });
      }

    }
    catch(error) {
      console.log(error);
      showToast(t('ProccedFail'), 'danger', 3000);
    }
  };

  onLogoutPress = () => {
    const { navigation, isRemoteConsent, otp } = this.props;
    if (isRemoteConsent) {
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: "SubjectAuthStack",
            state: {
              routes: [
                {
                  name: "SubjectAuthPage",
                  params: { otp: otp }
                }]}}],
        })
      );
    }
  };

  render() {
    const {
      subjectStatus,
      screenProps: { t },
      isRemoteConsent,
    } = this.props;
    const signType = this.getNextSignatureType();
    const signatureButton = this.getSignatureButton(signType);
    return (
      <Container>
        <View style={{ flex: 1 }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              paddingTop: "20%",
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            <Text
              style={{
                fontSize: fontType("Heading1"),
                color: Colors.text,
                padding: 5,
                textAlign: "center",
                fontFamily: "Inter",
                fontWeight: "bold",
              }}
            >
              {isRemoteConsent ? t("ThankYou") : t("OfficeThankYou")}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              paddingTop: "15%",
            }}
          >
            {subjectStatus === "DISCONTINUED" ? (
              <MaterialCommunityIcons
                name="close-circle-outline"
                style={{
                  color: Colors.error,
                  fontSize: 65,
                  textAlign: "center",
                }}
              />
            ) : (
              <MaterialCommunityIcons
                name="check-circle"
                style={{
                  color: Colors.checkCircle,
                  fontSize: 65,
                  textAlign: "center",
                }}
              ></MaterialCommunityIcons>
            )}
          </View>
          <View style={{ flexDirection: "row", justifyContent: "center" }}>
            {subjectStatus === "DISCONTINUED" ? (
              <Text
                style={{
                  textAlign: "center",
                  fontSize: fontType("subFont"),
                  fontFamily: "Inter",
                  paddingTop: 4,
                  color: Colors.text,
                }}
              >
                {t("DidNotSignConsent")}
              </Text>
            ) : (
              <Text
                style={{
                  textAlign: "center",
                  fontSize: fontType("subFont"),
                  paddingTop: 4,
                  color: Colors.text,
                  fontFamily: "Inter",
                }}
              >
                {t("ConsentSigned")}
              </Text>
            )}
          </View>
          {Platform.OS !== "web" && !signatureButton ? (
            <Text
              style={{
                textAlign: "center",
                fontSize: fontType("BasicFont"),
                fontFamily: "Inter",
                paddingTop: 4,
                color: Colors.coloredText,
              }}
            >
              {t("ReturnTab")}
            </Text>
          ) : null}
          {subjectStatus !== "DISCONTINUED" && signatureButton && (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                borderColor: "black",
                alignItems: "center",
                alignContent: "center",
                alignSelf: "center",
                border: "1px solid rgb(145, 85, 253)",
                marginTop: 50,
              }}
            >
              <TouchableOpacity
                onPress={() => this.navigateToNextSignatures(signType)}
                style={[signatureButtonStyles.buttonStyle]}
              >
                <View style={{ flexDirection: "row" }}>
                  <View>
                    <Text style={[signatureButtonStyles.buttonTxt]}>
                      {signatureButton}
                    </Text>
                  </View>
                  <View style={{ alignSelf: "center" }}>
                    <MaterialCommunityIcons
                      name="arrow-right-bold-circle"
                      style={{ fontSize: 25 }}
                      color="#ffffff"
                    />
                  </View>
                </View>
              </TouchableOpacity>
            </View>
          )}
          {subjectStatus !== "DISCONTINUED" &&
            !signatureButton &&
            isRemoteConsent && (
              <View style={{ paddingTop: "5%" }}>
                <TryalButton
                  title={"Logout"}
                  onClick={this.onLogoutPress}
                  buttonStyle={[constStyles.buttonStyle]}
                  buttonTxtStyle={[constStyles.buttonTxt]}
                  type={ButtonTypes.FILLED}
                />
              </View>
            )}
        </View>
      </Container>
    );
  }
}

CompletedScreen.defaultProps = {
  generateToken: () => null,
  selectedSubject: {},
  setCurrentUserType: () => null,
  clearDocSignPosition: () => null,
  clearSubjectDocumentSignatures: () => null,
  isRemoteConsent: false,
  signatureTypes: [],
  subjectStatus: "",
  otp: "",
};

CompletedScreen.propTypes = {
  generateToken: PropTypes.func,
  selectedSubject: PropTypes.object,
  setCurrentUserType: PropTypes.func,
  clearDocSignPosition: PropTypes.func,
  clearSubjectDocumentSignatures: PropTypes.func,
  isRemoteConsent: PropTypes.bool,
  signatureTypes: PropTypes.array,
  subjectStatus: PropTypes.string,
  otp: PropTypes.string,
  screenProps: PropTypes.shape({
    t: PropTypes.func.isRequired,
  }),
  navigation: PropTypes.shape({
    dispatch: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
  }).isRequired,
  currentUserType: PropTypes.any,
  retrieveSubjectDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  selectedSubject: state.selectedSubject,
  currentUserType: state.consentFlow.currentUserType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      generateToken,
      retrieveSubjectDetails,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CompletedScreen);
