import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Text, View } from "react-native";
import api from "../utils/api";
import { storeSubjectRemoteConsentDto } from "../actions/subjectRemoteConsentDto";
import { setCurrentUserType } from "../actions/consentFlow";
import { fontType } from "../styles/font";
import { storeSignatureType } from "../actions/subject";
import { clearDocSignPosition } from "../actions/update";
import { resetAppToInitialState } from "../actions/rootResetAction";
import { updateSession, updateStudyInSession } from "../actions/session";
import PropTypes from 'prop-types'

class SubjectAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLinkNotAvailable: false,
    };
  }

  componentDidMount() {
    const { navigation, resetAppToInitialState } = this.props;
    this._unsubscribe = navigation.addListener("focus", () => {
      resetAppToInitialState();
    });
    delete api.defaults.headers.common.Authorization;
    window.sessionStorage.clear();
    const otp = navigation.getParam("otp");
    this.retrieveSubjectRemoteConsentDto(otp);
  }

  retrieveSubjectRemoteConsentDto = async (otp) => {
    const {
      storeSubjectRemoteConsentDto,
      clearDocSignPosition,
      currentSubDocPosition,
      navigation,
      storeSignatureType,
      setCurrentUserType: loSetCurrentUserType,
      updateSession: loUpdateSession,
      updateStudyInSession: loUpdateStudyInSession
    } = this.props;
    try {
      clearDocSignPosition();
      const res = await api.get(`/remoteConsent/link/${otp}`);
      const subjectRemoteConsentDto = res.data;
      storeSubjectRemoteConsentDto(subjectRemoteConsentDto, otp);
      loSetCurrentUserType(subjectRemoteConsentDto.userType);
      loUpdateSession({primaryOrgCode: res?.data?.primaryOrganizationCode, site: res?.data?.siteId, userType:subjectRemoteConsentDto.userType})
      loUpdateStudyInSession({study: res?.data?.studyId})
      //  storeSignatureType(subjectRemoteConsentDto.userType);
      if (
        subjectRemoteConsentDto.isOtpExpired ||
        subjectRemoteConsentDto.status !== "NEEDS_SIGNATURE" ||
        subjectRemoteConsentDto.signStatus
      ) {
        this.setState({ showLinkNotAvailable: true });
      } else if (subjectRemoteConsentDto.hasUsername) {
        navigation.replace("SubjectSignInScreen", { isRemoteConsent: true });
      } else {
        navigation.replace("SubjectSignUpScreen", { isRemoteConsent: true });
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { showLinkNotAvailable } = this.state;
    const { subjectRemoteConsentData } = this.props;
    if (showLinkNotAvailable) {
      return (
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          {subjectRemoteConsentData.phoneNo ? (
            <Text
              style={{
                fontSize: fontType("Heading1"),
                color: "#006699",
                padding: 10,
              }}
            >
              This link is no longer valid. Please contact{" "}
              {subjectRemoteConsentData.siteName} at{" "}
              {subjectRemoteConsentData.phoneNo} if you have any questions.{" "}
            </Text>
          ) : (
            <Text
              style={{
                fontSize: fontType("Heading1"),
                color: "#006699",
                padding: 10,
              }}
            >
              This link is no longer valid. Please contact{" "}
              {subjectRemoteConsentData.siteName} if you have any questions.
            </Text>
          )}
          <Text
            style={{
              fontSize: fontType("Heading1"),
              color: "#006699",
              padding: 10,
            }}
          >
            Thank you.{" "}
          </Text>
        </View>
      );
    } else {
      return (
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <Text
            style={{
              fontSize: fontType("Heading1"),
              color: "#006699",
              padding: 10,
            }}
          >
            Please wait while we authorize the link.{" "}
          </Text>
        </View>
      );
    }
  }
}

SubjectAuth.defaultProps = {
  navigation: {},
  currentSubDocPosition: null,
  subjectRemoteConsentData: {},
};

SubjectAuth.propTypes = {
  navigation: PropTypes.object,
  storeSubjectRemoteConsentDto: PropTypes.func.isRequired,
  clearDocSignPosition: PropTypes.func.isRequired,
  currentSubDocPosition: PropTypes.any,
  storeSignatureType: PropTypes.func.isRequired,
  setCurrentUserType: PropTypes.func.isRequired,
  resetAppToInitialState: PropTypes.func.isRequired,
  updateSession: PropTypes.func.isRequired,
  updateStudyInSession: PropTypes.func.isRequired,
  subjectRemoteConsentData: PropTypes.object,
};


const mapStateToProps = (state) => ({
  // subjectStatus: state.subject.status,
  subjectRemoteConsentData: state.subjectRemoteConsentDto,
  currentSubDocPosition: state.currentSubDocPosition,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      storeSubjectRemoteConsentDto,
      setCurrentUserType,
      storeSignatureType,
      clearDocSignPosition,
      resetAppToInitialState,
      updateSession,
      updateStudyInSession
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SubjectAuth);
