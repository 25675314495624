import React, { Component } from "react";
import { Button, Card } from "native-base";
import { Text, View, ScrollView, Platform, KeyboardAvoidingView, Image, ActivityIndicator } from "react-native";
import {
    widthPercentageToDP as wp,
    heightPercentageToDP as hp
} from "react-native-responsive-screen";
import { styles } from "../login/styles/loginScreenStyles";
import { createForm } from "rc-form";
import TextInputItem from './TextInputItem';
import { Colors } from "../../constants/ui/colorScheme";
import { ConsentDataContext } from "../../providers/ConsentDataProvider";
import api from "../../utils/api";
import { generateBasicAuthToken } from '../../utils/util';
import showToast from '../../utils/toast';
import Constants from 'expo-constants';
import {fontType} from '../../styles/font';
import {constStyles} from '../../styles/constStyles';
// import LoadingOverlay from 'react-loading-overlay';
// import { NavigationEvents } from "react-navigation";
import MyLoader from '../../utils/webLoader';
import SubjectSignUp from "./SubjectSignUp";
import { Formik } from "formik";
import * as Yup from "yup";
import NoEmailPopUp from "../../utils/NoMailPopUp";
import NoRemoteConsentMailPopUp from "../../utils/NoRemoteConsentMailPopUp";
import Constant from '../../utils/constants';
import constants from "../../utils/constants.js";
const { ContextProperties } = constants

class SubjectSignIn extends Component {

    state = {
       isloading: false,
       isNoMail: false,
       isButtonDisabled: true,
        currentUser: ''
    }
        

    componentDidMount() {
        const { currentUserType } = this.props;
        switch (currentUserType) {
            case 1: 
            this.setState({
                currentUser: 'Subject'
            });
            break;
            
            case 2: 
            this.setState({
                currentUser: 'LAR'
            });
            break;
            
            case 4: 
            this.setState({
                currentUser: 'Witness'
            });
            break;
      }
 }

    isDisabled = (userName) => {
        if(userName!=''){
            this.setState({
                isButtonDisabled : false
            })
        } else {
            this.setState({
                isButtonDisabled : true
            })
        }
        // setFieldValue('userName',userName);
    }
    setInitialValues = () => {
        const initialValues = {
            userName: '',
            password: ''
        };
        return initialValues;
    }

    
    submitValues = async (values) => {
        const { subjectId,navigation, storeSelectedSubjectDetailsinSubject, storeSelectedSubject, setDocuments, currentUserType,retrieveSubDocsData, storeClientUserDetails ,subjectDetails} = this.props;
        let isRemoteConsent = false;
        if(typeof navigation.getParam('isRemoteConsent') == 'boolean' && navigation.getParam('isRemoteConsent') == true) {
            isRemoteConsent = true;
        } 
        if(typeof navigation.getParam('isRemoteConsent') == 'string' && navigation.getParam('isRemoteConsent') == "true") {
            isRemoteConsent = true;
        } 
        const subject = {
            id : subjectId,
            userName: values.userName,
            loginPassword: values.password, 
        }

        const data = {
            subject: subject,
            userType : currentUserType
        }



        try {
            this.setState({
                isloading : true
            })
            const res = await api.post(`/${subjectDetails.primaryOrganizationCode}/studies/${subjectDetails.studyId}/sites/${subjectDetails.siteId}/subjects/signin`, data);
            api.defaults.headers.common.Authorization = res.headers['authorization'];

            if(Platform.OS == 'web') {
                const authToken = res.headers['authorization'];
                window.sessionStorage.setItem('token', authToken);
            }

            const loSubject = res.data.subject;
            const loClientUserDetails = res.data.clientUserDetails;
            
            const selSubject = {
                firstName:loSubject.firstName,
                lastName:loSubject.lastName,
                fullName: loSubject.fullName,
                middleName: loSubject.middleName,
                id:loSubject.id,
                isRegistered:true,
                subjectConsentId: loSubject.subjectConsent.id,
                consentVersionId: loSubject.currentConsentVersionId,
                status: loSubject.status,
                isReconsent: loSubject.subjectConsent.reconsent,
                lastConsentedIrbVersion: loSubject.lastConsentedIrbVersion,
                consentedOn: loSubject.consentedOn,
                navTour:[],
                language: loSubject.locale,
                languageId: loSubject.languageId,
                subjectConsent: loSubject.subjectConsent,
                email: loSubject.email,
                isRemoteConsent: isRemoteConsent,
                larAsWitness: loSubject.larAsWitness,
                larFirstName: loSubject.larFirstName,
                larLastName: loSubject.larLastName,
                witnessFirstName: loSubject.witnessFirstName,
                witnessLastName: loSubject.witnessLastName,
                larRelation: loSubject.larRelation,
                isKnowledgeReviewDone: loSubject.subjectConsent.isKrDone,
                isRemoteConsent: isRemoteConsent,
                studyId: subjectDetails.studyId,
                siteId: subjectDetails.siteId,
                primaryOrganizationCode: subjectDetails.primaryOrganizationCode
            }
            const clientUserDetials = {
                email: loClientUserDetails.email,
                phoneNo: loClientUserDetails.phoneNo,
            }
            storeSelectedSubjectDetailsinSubject(selSubject);
            storeSelectedSubject({...loSubject, isKnowledgeReviewDone: loSubject.subjectConsent.isKrDone});
            storeClientUserDetails(clientUserDetials)
            showToast("Signed in successfully.", "success", 2000);
            
            let res1 = null;
            if(selSubject.larAsWitness && (currentUserType == 2 || currentUserType == 4)){
                res1 = await api.get(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${loSubject.id}/subjectConsents/${loSubject.subjectConsent.id}/documents/all`);
            } else {
                res1 = await api.get(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${loSubject.id}/subjectConsents/${loSubject.subjectConsent.id}/documents/remote?userType=${currentUserType}`);
            }
            setDocuments(res1.data,res.data.larAsWitness);
            
            // to retrive form Data for the subject/lar
            retrieveSubDocsData(loSubject.id,loSubject.languageId, loSubject.subjectConsent.id, loSubject.currentConsentVersionId);

            this.setState({
                isloading : false
            });

            if (loSubject.passwordExpired ) {
                this.setState({
                         isloading : false
                     });
                this.resetPassword("email");
                 
            }else if (isRemoteConsent) {
                navigation.navigate('Consent', { screen: 'MetaDataLoading' })
            } else {
                navigation.navigate('Consent', { screen: 'ConsentOverviewScreen' })
            }  
            // loSetCurrentUserType(userType);
        

        } catch(e){
            this.setState({ isloading: false });
            console.log(e);
            delete api.defaults.headers.common.Authorization;
            const result = e.response ? e.response.status : 'NetworkError';
            if (result === 401 || result === 400) {
                showToast("Invalid username or password.", 'danger', 4000);
            } else if (result === 423) {
                showToast("User locked.", 'danger', 4000);
            } else {
                showToast('Network Error.', 'danger', 2000);
            }
        }
    }

    resetPassword = async otpType => {
        const { navigation, subjectId, data, userName, currentUserType } = this.props;
        const obj = {
            subjectId: subjectId,
            otpType: otpType,
            userType: currentUserType
        };
        try {
                
                let isRemoteConsent = false;
                if(typeof navigation.getParam('isRemoteConsent') == 'boolean' && navigation.getParam('isRemoteConsent') == true) {
                    isRemoteConsent = true;
                } 
                if(typeof navigation.getParam('isRemoteConsent') == 'string' && navigation.getParam('isRemoteConsent') == "true") {
                    isRemoteConsent = true;
                } 
               
                navigation.navigate("SubjectResetPasswordPage", { 
                    navobj : JSON.stringify({
                        subjectId: subjectId,
                        from:'SUBJECT_SIGNIN_PASSWORDEXPIRED',
                        subDocSignNavProps:null,
                        isRemoteConsent: isRemoteConsent,
                        userName: userName
                    }),
                    transactionId: null
                });  
            } catch (error) {
                console.log(error);
            }
            
        
       
    };

    sendOtp = async otpType => {
        const { navigation, subjectId, data, userName, currentUserType } = this.props;
        const obj = {
            subjectId: subjectId,
            otpType: otpType,
            userType: currentUserType,
            otp: {}
        };
        if(data.email){
            try {
                this.setState({ loading: true });
                const res = await api.post(`/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${subjectId}/otps`
                    , obj);
                showToast(
                    'OTP has been sent to Email.',
                    "success",
                    2000
                );
                this.setState({ loading: false });
                let isRemoteConsent = false;
                if(typeof navigation.getParam('isRemoteConsent') == 'boolean' && navigation.getParam('isRemoteConsent') == true) {
                    isRemoteConsent = true;
                } 
                if(typeof navigation.getParam('isRemoteConsent') == 'string' && navigation.getParam('isRemoteConsent') == "true") {
                    isRemoteConsent = true;
                } 
                const validationObj = JSON.stringify({
                    otpId: res.data.id,
                    otpType: otpType,
                    subjectId: subjectId,
                    from: 'SUBJECT_SIGNIN',                    
                    isRemoteConsent: isRemoteConsent                    
                  });
                navigation.navigate("SubjectValidateOtpPage", { 
                    validationObj : JSON.stringify({
                        otpId: res.data.id,
                        otpType: otpType,
                        subjectId: subjectId,
                        from:'SUBJECT_SIGNIN',
                        isRemoteConsent: isRemoteConsent,
                        userName: userName
                    })
                });
                 
            } catch (error) {
                console.log(error);
                this.setState({ loading: false });
                if (error.response) {
                    showToast('Failed to send OTP to Email.', "danger", 2000);
                } else {
                    showToast("Network Error.", "danger", 2000);
                }
            }
            
        }else {
            this.toggleIsNoMailPopUp();
        }
       
    };

    toggleIsNoMailPopUp = () => {
        this.setState(prevState => ({
          ...prevState,
          isNoMail: !prevState.isNoMail
        }));
      }
    

    render() {
        const {navigation, data } = this.props;
        const { isloading, isNoMail, isButtonDisabled, currentUser } = this.state;
        return (
            <ScrollView>
                {/* <NavigationEvents
                    onDidFocus={() => {
                        validateSession(false);
                    }}
                /> */}
                <MyLoader  active={isloading}/>
                <KeyboardAvoidingView behavior="padding" enabled>

                    {/* --------------------- Logo --------------------------- */}
                    <View
                        style = {{
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: 20
                        }}
                    >
                        {/* <Image
                            source={require("../../../assets/bio.png")}
                            style = {{ height: hp("7%"), width: wp("25%") }}
                            // resizeMode="center"
                            resizeMode='contain'
                        /> */}
                        <Text style = {
                            {
                                alignSelf:'center',
                                justifyContent:'center',
                                fontSize: fontType('Heading1'),
                                fontFamily: "Inter",}
                        }>
                            Informed Consent Login
                        </Text>
                    </View>

                    {/* --------------------- Card ---------------------------- */}
                    <View style = {{ justifyContent: "center", marginTop:10 }}>
                        <Card style = {[styles.container, Platform.OS !== 'web' && {marginTop:30}]}>
                        <Formik
                            initialValues={this.setInitialValues()}
                            validationSchema={
                                Yup.object({
                                    userName: Yup.string().required('Please enter Username.'),
                                    password: Yup.string().required('Please enter Password.')
                                })
                            }
                            onSubmit={(values, formikActions) => {
                            this.submitValues(values);
                            setTimeout(() => {
                                formikActions.setSubmitting(false)
                            }, 500);
                            }}>
                        {props => (
                            <View
                                style = {{
                                    flex: 1
                                }}
                            >
                                {/* ------------------ Label ---------------------- */}
                                <View
                                    style = {{
                                        flex: 1,
                                        justifyContent: "center",
                                        marginTop: Platform.OS !== 'web' ? 0 :-25,
                                        paddingVertical:Platform.OS !== 'web' ? 10 : 0,
                                    }}
                                >
                                    <Text style = {
                                        {
                                            fontSize: fontType('Heading'),
                                            fontFamily: "Inter",
                                            fontWeight:'400',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                        }  
                                    }>
                                    {
                                        data.siteName
                                    } 
                                    </Text>
                                    <Text style = {
                                        {
                                            fontSize: fontType('Heading1'),
                                            fontFamily: "Inter",
                                            fontWeight:'400',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                        }
                                    }>
                                    {
                                        data.subjectName
                                    }
                                    </Text>
                                    <Text style = {
                                        {
                                            fontSize: fontType('subFont'),
                                            fontFamily: "Inter",
                                            fontWeight:'400',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                        }         
                                    }>
                                    {
                                        data.email
                                    } 
                                    </Text>
                                </View>

                                {/* ------------------ Username ---------------------- */}
                                <View style = {{marginVertical:15}}>   
                                    <TextInputItem
                                        value = {props.values.userName}
                                        placeholderValue={'Username'}
                                        
                                        onChange={(text)=>{
                                            
                                            props.setFieldValue('userName', text)}}
                                    />
                                    {props.errors.userName && props.touched.userName && (
                                        <Text style = {{ color: 'red',fontSize: fontType('subFont') }}>{props.errors.userName}</Text>
                                    )}
                                </View>
                                
                                {/* ------------------ Password ---------------------- */}
                                <View style = {{marginVertical:15}}>   
                                    <TextInputItem
                                        value={props.values.password}
                                        placeholderValue={'Password'}
                                        secureTextEntry={true}
                                        onChange={props.handleChange('password')}
                                        maxLength={100}
                                    />
                                    {props.errors.password && props.touched.password && (
                                        <Text style = {{ color: 'red',fontSize: fontType('subFont') }}>{props.errors.password}</Text>
                                    )}
                                </View>

                                {/* ------------- Sign in button --------------------- */}
                                <View style = {{marginVertical: Platform.OS == 'web' ? 5 : 40}}>
                                    <Button 
                                        success
                                        style = {{justifyContent:'center', backgroundColor: Colors.buttonwithbg}}
                                        onPress={props.handleSubmit}
                                    >
                                        <Text style = {{textAlign:'center', color:'white'}} >
                                            LOGIN
                                        </Text>
                                    </Button>
                                </View> 

                                {/* ------------------ Forgot password ---------------------- */}
                                <View style={
                                    {
                                        justifyContent: "center",
                                        alignItems: 'center',
                                        flex: 1,
                                        marginVertical: 20
                                    }
                                }>
                                    <Text style={{ color: Colors.buttonwithbg }}
                                        onPress={() => {
                                            this.sendOtp("email");
                                        }}
                                    >Forgot Password?</Text>
                                    <Text style={{marginTop:15,  fontSize: 12,  color: Colors.iconColor}}>App Version : {Constant.APP_VERSION}</Text>
                                </View>
                                <NoRemoteConsentMailPopUp isNoMail={isNoMail} onOk={this.toggleIsNoMailPopUp} currentUser={currentUser} />
                            </View>
                            )}
                        </Formik>
                    </Card>
                </View>    
            </KeyboardAvoidingView>
        </ScrollView>);
    }
}

export default SubjectSignIn;
