import React, { Component } from 'react';
import { View, Text, AsyncStorage } from 'react-native';
import _ from 'lodash';
import api from '../utils/api';
import { ConsentDataContext } from '../providers/ConsentDataProvider';
import { Promise } from 'core-js';
import {fontType} from '../styles/font';
import showToast from '../utils/toast';
import constants from '../utils/constants'
import PropTypes from 'prop-types'

 class DownloadVideosAndStoreLanguages extends Component {
    static contextType = ConsentDataContext;
    constructor(props) {
        super(props);
        this.state = {
            isStoringData: true,
        }
    }


    componentDidMount = () => {
        this.saveVideos();
    }

     saveVideos = async() => {
        const { navigation } = this.props;
         try {
             this.setState({isStoringData: true});
             const res = await api.get(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/metadata`);
             const promises = [];
             if (res.data) {
                 const data = res.data;
                 let assets = [];
                Promise.all(promises).then(res=>{
                    this.storeAssetsAndLanguages(data, assets);                 
                });
             }
         } catch (error) {
             console.log(error);
             const result = error.response ? error.response.status : 'NetworkError';
             if (result === 400) {
                showToast('Something went wrong.', 'danger', 4000);
             }  else {
                 showToast('Network Error.', 'danger', 4000);
             }
             setTimeout(() => {
                navigation.navigate('LoginPage');  
             }, 5000);
           
            }
     }

     storeAssetsAndLanguages = async (languages, assets) => {
        const languagesdata =  JSON.stringify(languages);
        this.storeLanguage(languagesdata);
        if(assets.length >= 1) {
            const loAssets = JSON.stringify(assets);
            await AsyncStorage.setItem('assets', loAssets);
        }
     }

    storeLanguage = (languagesdata) => {
        const { navigation } = this.props;
        try {
            let promises = [];
            promises.push(this.context.saveLanguages(languagesdata));
            Promise.all(promises).then(res=>{
                navigation.replace('StudySiteAccessInfoScreen');                
            });               
        } catch (error) {
            console.log(error);
            navigation.navigate('LoginPage');
            
        }
     }

    render() {
        return (
            <View style={{flex:1, alignItems:'center',justifyContent:'center', backgroundColor: '#ffffff' }}>
                <Text style={{ fontSize: fontType('Heading1'), color: '#006699', padding: 10 }}>Loading...</Text>
            </View>
        )
    }
}

DownloadVideosAndStoreLanguages.propTypes = {
    navigation: PropTypes.object.isRequired
};

export default DownloadVideosAndStoreLanguages;


